import { Box, Center, Flex, Stack, Text, useToast, VStack } from "@chakra-ui/react"
import { useEffect, useMemo, useState } from "react"
import { FaFileExcel, FaFilePdf } from "react-icons/fa"
import { FiDownload } from "react-icons/fi"
import { RiDeleteBin7Line } from "react-icons/ri"
import { get } from "../../../../Components/firebase/api/db"
import { SmallFill } from "../../../../Components/Loaders"
import MyCard from "../../../../Components/micro/Card"
import ConfirmDialog from "../../../../Components/micro/ConfirmDialog"
import MyPopover from "../../../../Components/micro/MyPopover"
import NotFound from "../../../../Components/micro/NotFound"
import { Option, ReportParametrsType } from "../../Selector/types"
import dayjs from "../../../../Components/Functions/dayjs"
import TotalStats from "./TotalStats"
import Charts from "./Charts"
import { viewAll } from "../FactoryReport/Machine/MachineBreakdown/PieChart"
import secFormatter from "../../../../Components/Functions/formatters/secFormatter"
import landscapePDF from "../../../../Components/Exporters/landscapePDF"
import excelDownload, { ExcelSectionType } from "../../../../Components/Exporters/excelDownload"

interface DataToSet {
    name: string;
    target: {
        total: number;
        completed: number;
    },
    stats: {
        ontime: number;
        downtime: number;
        efficiency: number;
    },
    hourly: {
        datetime: string;
        downtime: number;
        ontime: number;
        efficiency: number;
        production: number;
        targetProduction: number;
    }[],
    timeline: {
        start: number;
        end: number;
        due: number;
    }
}
const ProductionTargetReport = ({
    parameters
}: {
    parameters: ReportParametrsType["production-targets"]
}) => {
    const toast = useToast();
    const [data, setData] = useState<null | DataToSet | "NOT_FOUND">(null);
    const [isBusy, setIsBusy] = useState(false);
    const [machineName, moldsName, targetName] = useMemo(() => {
        return [
            (parameters.option as Option)?.label || "",
            (parameters.secondaryOption as Option)?.label || "",
            (parameters.thirdOption as Option)?.label || "",
        ]
    }, [parameters]);
    const selectedTitle = useMemo(() => {
        return `${machineName} | ${moldsName} | ${targetName}`
    }, [machineName, moldsName, targetName]);

    useEffect(() => {
        const func = async () => {
            const dataToSet: DataToSet = {
                name: "",
                target: {
                    total: 0,
                    completed: 0
                },
                stats: {
                    ontime: 0,
                    downtime: 0,
                    efficiency: 0
                },
                hourly: [],
                timeline: {
                    start: 0,
                    end: 0,
                    due: 0
                }
            }
            setData(null);
            const snap = await get(`production-targets/completed/${(parameters.option as Option)?.value}/${(parameters.secondaryOption as Option)?.value}/${(parameters.thirdOption as Option)?.value}`);
            if (!snap.exists()) {
                setData("NOT_FOUND");
                return;
            }
            const data = snap.val();
            dataToSet.name = data.name;
            dataToSet.timeline.start = dayjs.unix((data.startedAt || 0) / 1000).unix();
            dataToSet.timeline.end = dayjs.unix((data.completedAt || 0) / 1000).unix();
            dataToSet.timeline.due = dayjs(data.due).unix();
            dataToSet.target.total = data.target.total;
            dataToSet.target.completed = data.target.current;
            dataToSet.stats.efficiency = (data.efficiency || 0) * 100;
            for (const key in data.hours) {
                // format: 2021-09-01-01
                const hour = data.hours[key];
                const date = dayjs(key.split("-")
                    .slice(0, 3)
                    .join("-")).format("YYYY-MM-DD");
                const time = hour.time;
                dataToSet.stats.ontime += hour.ontime;
                dataToSet.stats.downtime += hour.downtime
                dataToSet.hourly.push({
                    datetime: `${date} ${time}`,
                    downtime: hour.downtime,
                    ontime: hour.ontime,
                    efficiency: +(
                        hour.ontime / (hour.ontime + hour.downtime) * 100
                    ).toFixed(2),
                    production: hour.production,
                    targetProduction: data.target?.hourlyTarget || 0
                })
            }
            if (!dataToSet.stats.efficiency) {
                dataToSet.stats.efficiency = (dataToSet.stats.ontime / (dataToSet.stats.ontime + dataToSet.stats.downtime)) * 100;
            }
            setData(dataToSet);
        }
        func();
    }, [parameters]);

    const downloadPDF = async () => {
        if (data === null || data === "NOT_FOUND") return;
        setIsBusy(true);
        const tables = {} as {
            [key: string]: string[][]
        };
        const hourlyStatsTable = [[
            "Time",
            "Act Prod",
            "Exp Prod",
            "Ontime",
            "Downtime",
            "Efficiency"
        ]];
        for (const hour of data.hourly) {
            const time = hour.datetime
            hourlyStatsTable.push([
                dayjs(time).format("YYYY-MM-DD HH:mm"),
                viewAll(hour.production, "production"),
                viewAll(hour.targetProduction, "production"),
                secFormatter(hour.ontime),
                secFormatter(hour.downtime),
                hour.efficiency + "%"
            ])
        }
        tables["Hourly Stats"] = hourlyStatsTable;
        await landscapePDF({
            filename: `production-report(${data.name}).pdf`,
            headings: {
                left: "Production Target Report",
                middle: data.name,
                right: `${dayjs.unix(data.timeline.start).format("YYYY-MM-DD HH:mm")} - ${dayjs.unix(data.timeline.end).format("YYYY-MM-DD HH:mm")}`
            },
            stats: {
                "Target Set": viewAll(data.target.total, "production"),
                "Target Achieved": viewAll(data.target.completed, "production"),
                "Ontime": secFormatter(data.stats.ontime),
                "Downtime": secFormatter(data.stats.downtime),
                "Efficiency": data.stats.efficiency + "%",
                "Due Time": dayjs.unix(data.timeline.due).format("YYYY-MM-DD HH:mm")
            },
            tables
        });
        setIsBusy(false);
    }
    const downloadExcel = async () => {
        try {
            if (data === null || data === "NOT_FOUND") return;
            setIsBusy(true);
            const sections = [] as ExcelSectionType[];
            const totalStatsSection: ExcelSectionType = {
                mainHeading: `Production Target Report (${data.name})`,
                subHeadings: [
                    "Time",
                    "Act Prod",
                    "Exp Prod",
                    "Ontime",
                    "Downtime",
                    "Efficiency"
                ],
                data: [] as ExcelSectionType['data'],
                footer: ["Total", 0, "", 0, 0, ""] as ExcelSectionType['footer']
            };
            for (const hour of data.hourly) {
                const time = hour.datetime;
                totalStatsSection.data.push([
                    dayjs(time).format("YYYY-MM-DD HH:mm"),
                    viewAll(hour.production, "production"),
                    viewAll(hour.targetProduction, "production"),
                    secFormatter(hour.ontime),
                    secFormatter(hour.downtime),
                    hour.efficiency + "%"
                ]);
                (totalStatsSection.footer[1] as number) += hour.production;
                (totalStatsSection.footer[3] as number) += hour.ontime;
                (totalStatsSection.footer[4] as number) += hour.downtime;
            };
            totalStatsSection.footer[1] = viewAll(totalStatsSection.footer[1] as number, "production");
            totalStatsSection.footer[3] = secFormatter(totalStatsSection.footer[3] as number);
            totalStatsSection.footer[4] = secFormatter(totalStatsSection.footer[4] as number);
            totalStatsSection.footer[5] = data.stats.efficiency.toFixed(2) + "%";
            sections.push(totalStatsSection);
            await excelDownload({
                filename: `production-report(${data.name}).xlsx`,
                heading: "Production Target Report",
                sections,
            });
        } catch (err) {
            console.log(err);
            toast({
                title: "Error",
                description: "Something went wrong",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        }
        setIsBusy(false);
    }

    return <Flex gap={5} flexDir={"column"}>
        <Box display="flex" alignItems={"center"} justifyContent="space-between">
            <Text fontWeight={600}>Production Target Report: <span style={{
                color: "var(--chakra-colors-primary)",
                fontWeight: 500,
                fontSize: "0.8rem"
            }}>{selectedTitle}</span></Text>
            <Stack
                alignItems={{
                    base: "flex-start",
                    sm: "center"
                }}
                gap={2}
                flexDir={{
                    base: "column",
                    sm: "row"
                }}>
                <MyPopover placement="bottom-end">
                    <Text userSelect={"none"} cursor={"pointer"} transition="all ease 0.2s" _hover={{
                        color: "primary"
                    }} fontWeight={600} fontSize="sm" display="flex" alignItems={"center"} justifyContent="space-between" gap={1} color="orange.300">
                        <FiDownload />
                        Download
                    </Text>
                    <Box>
                        <VStack w="100%" alignItems={"flex-start"}>
                            <Text onClick={() => { !isBusy && downloadPDF() }} userSelect={"none"} cursor={"pointer"} transition="all ease 0.2s" _hover={{
                                opacity: 1
                            }} fontWeight={600} fontSize="sm" display="flex" alignItems={"center"} justifyContent="space-between" gap={1} opacity="0.8">
                                <FaFilePdf />
                                Download PDF
                            </Text>
                            <Text onClick={() => { !isBusy && downloadExcel() }} userSelect={"none"} cursor={"pointer"} transition="all ease 0.2s" _hover={{
                                opacity: 1
                            }} fontWeight={600} fontSize="sm" display="flex" alignItems={"center"} justifyContent="space-between" gap={1} opacity="0.8">
                                <FaFileExcel />
                                Download Excel
                            </Text>
                        </VStack>
                    </Box>
                </MyPopover>
                <ConfirmDialog scope="danger" text="Are you sure you want to delete this report, All data related to this report will be deleted." onConfirm={() => {
                    toast({
                        title: "Unauthorized",
                        description: "You are not authorized to delete this report",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    })
                }}>
                    <Text userSelect={"none"} cursor={"pointer"} transition="all ease 0.2s" _hover={{
                        color: "red"
                    }} fontWeight={600} fontSize="sm" display="flex" alignItems={"center"} justifyContent="space-between" gap={1} color="red.400">
                        <RiDeleteBin7Line />
                        Delete
                    </Text>
                </ConfirmDialog>
            </Stack>
        </Box>
        {data === null ?
            <MyCard as={Center} minH="50vh">
                <SmallFill />
            </MyCard> :
            data === "NOT_FOUND" ?
                <MyCard as={Center} minH="50vh">
                    <NotFound />
                </MyCard> :
                <Flex gap={5} flexDir="column">
                    <TotalStats data={data} />
                    <Charts data={data} />
                </Flex>
        }
    </Flex>
}

export default ProductionTargetReport;
export type {
    DataToSet
}