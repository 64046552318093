import { get } from "../../Components/firebase/api/db";
import dayjs from "../../Components/Functions/dayjs";
import secondsToHourMin from "../../Components/Functions/converters/secondsToHourMin";
import store from "../../Components/Store";
import {
  EnvListAtom,
  equipmentsAtom,
  machinesAtom,
  offlineTimeAtom,
  uidAtom,
} from "../../Components/Store/atoms";

interface statProp {
  value: number;
  change: number;
  chart: number[];
}
interface StatsProps {
  shots: statProp;
  production_meters: statProp;
  production: statProp;
  material: statProp;
  electricity: statProp;
}
interface machineProps {
  name: string;
  shots: number;
  production_meters: number;
  production: number;
  material: number;
  electricity: number;
  status: string;
  mold: string;
  at: number;
}
interface equipmentProps {
  name: string;
  electricity: number;
  status: string;
  at: number;
}
interface machinesHour {
  hour: string;
  machine: string;
  value: {
    shots: number;
    production_meters: number;
    production: number;
    material: number;
    electricity: number;
  };
}
interface equipmentHour {
  hour: string;
  equipment: string;
  value: number;
}
interface ActivityProps {
  name: string;
  state: string;
  status: "OK" | "WARNING" | "ERROR";
  title: string;
  unix: number;
}
interface returnProps {
  total: StatsProps;
  machines: {
    [key: string]: machineProps;
  };
  equipments:
    | {
        [key: string]: equipmentProps;
      }
    | "NOT_FOUND";
  units: {
    [key: string]: any;
  };
  machineHourly: machinesHour[];
  equipmentHourly: equipmentHour[] | "NOT_FOUND";
  notFound: boolean;
  supervisor: string;
  hours: any[];
  activities: "NOT_FOUND" | ActivityProps[];
}
const stat = {
  value: 0,
  change: 0,
  chart: [] as number[],
};
interface selectedFactoryType {
  name: string;
  value: number;
  only: boolean;
}
const fetch = (
  date: string | undefined = dayjs().format("YYYY-MM-DD"),
  shift_a_start: number | undefined = 28800,
  factories: any[],
  selectedFactory: selectedFactoryType,
): Promise<returnProps> => {
  const mySelectedFactory: any = factories[selectedFactory.value];
  const shift_start_minutes = (shift_a_start % 3600) / 60;
  const min = dayjs().minute();
  const thisHourpastMin =
    min < shift_start_minutes
      ? min + shift_start_minutes
      : min - shift_start_minutes;
  return new Promise(async (resolve, reject) => {
    const stats = {
      total: {
        shots: { ...stat },
        production_meters: { ...stat },
        production: { ...stat },
        material: { ...stat },
        electricity: { ...stat },
      },
      machines: {},
      equipments: "NOT_FOUND" as returnProps["equipments"],
      units: {} as {
        [key: string]: any;
      },
      machineHourly: [] as machinesHour[],
      equipmentHourly: [] as equipmentHour[] | "NOT_FOUND",
      supervisor: "unkown",
      notFound: false,
      hours: [] as string[],
      activities: "NOT_FOUND" as returnProps["activities"],
    };
    const promises = [];
    const machinesAllowed = store.get(machinesAtom);
    const equipmentsAllowed = store.get(equipmentsAtom);
    const envsAllowed = store.get(EnvListAtom);
    const uid = store.get(uidAtom);
    const offlineTime = store.get(offlineTimeAtom);
    promises.push(get(`/reports/factory/daily/${date}`));
    promises.push(get(`/reports/factory/hourly/${date}/`));
    promises.push(get(`machines`));
    promises.push(get(`equipments`));
    promises.push(get(`activities`));
    promises.push(get(`units`));
    promises.push(get(`address`));
    const [
      snap,
      hourlySnap,
      machineSnap,
      equipmentsSnap,
      activitySnap,
      unitSnap,
      addressSnap,
    ] = await Promise.all(promises);
    const activities = Object.values(activitySnap.val() || {}).reverse();
    const _activities = [];
    const address = addressSnap.val() || {};
    for await (const activity of activities) {
      const { name, state, status, title, unix } = activity as any;
      _activities.push({
        name,
        state,
        status,
        title,
        unix,
      });
    }
    if (_activities.length > 0) stats.activities = _activities;
    let cacheUnit: any = unitSnap.val();
    const usedUnits = new Set();
    stats.units = cacheUnit;
    if (!unitSnap.exists()) {
      stats.units = {
        "1": {
          cleanDisconnect: false,
          connected: false,
          last_seen: 0,
        },
      };
    } else {
      if (mySelectedFactory?.units) {
        for await (const unit of Object.keys(stats.units)) {
          if (!mySelectedFactory.units.includes(+unit)) {
            delete cacheUnit[unit];
          }
        }
      }
    }
    cacheUnit = Object.fromEntries(
      Object.entries(cacheUnit || {}).map(([key, v]) => {
        const value = v as any;
        const { lastContact, last_seen } = value;
        const diff = dayjs().diff(dayjs.unix(lastContact || 0), "minute");
        const connected = diff <= offlineTime;
        value.connected = connected;
        value.last_seen = lastContact || last_seen;
        return [key, value];
      }),
    );
    if (!snap.exists() || !hourlySnap.exists() || !machineSnap.exists()) {
      stats.notFound = true;
      resolve(stats);
      return;
    }
    const myMachines = Object.fromEntries(
      Object.entries(machineSnap.val() || {}).sort(
        (a: any, b: any) => a[1].sort - b[1].sort,
      ),
    );
    let data = snap.val();
    if (data?.factories && mySelectedFactory) {
      data = {
        ...data,
        ...data.factories[selectedFactory.value],
      };
    } else if (mySelectedFactory === undefined && data?.factories) {
      const factory = { ...data.factories[0] };
      factory.supervisor_a = [];
      factory.supervisor_b = [];
      factory.supervisor_c = [];
      for await (const f of Object.values(data.factories) as any) {
        factory.supervisor_a.push(f.supervisor_a);
        factory.supervisor_b.push(f.supervisor_b);
        factory.supervisor_c.push(f.supervisor_c);
      }
      factory.supervisor_a = factory.supervisor_a.join(", ");
      factory.supervisor_b = factory.supervisor_b.join(", ");
      factory.supervisor_c = factory.supervisor_c.join(", ");
      data = {
        ...data,
        ...factory,
      };
    }
    const rn = dayjs();
    const rnSecs = rn.hour() * 3600 + rn.minute() * 60 + rn.second();
    const supervisor = (() => {
      let supervisor = "A - " + data.supervisor_a;
      if (isNaN(data.shift_b_start)) supervisor = "A - " + data.supervisor_a;
      else if (rnSecs > +data.shift_a_start && rnSecs < +data.shift_b_start)
        supervisor = "A - " + data.supervisor_a;
      else if (isNaN(data.shift_c_start))
        supervisor = "B - " + data.supervisor_b;
      else if (rnSecs > +data.shift_b_start && rnSecs < +data.shift_c_start)
        supervisor = "B - " + data.supervisor_b;
      else if (rnSecs > +data.shift_c_start && rnSecs < +data.shift_a_start)
        supervisor = "C - " + data.supervisor_c;
      return supervisor;
    })();
    stats.supervisor = supervisor;
    stats.total.shots.value = data.shots || 0;
    stats.total.production_meters.value = data.production_meters || 0;
    stats.total.production.value = data.production || 0;
    stats.total.material.value = data.material_usage || 0;
    stats.total.electricity.value = data.electricity_usage || 0;
    if (uid === "1fC3tw0Ad3RPbD7jxB4s8wJwLTu2") usedUnits.add("2");
    const hourlyData = hourlySnap.val();
    const hours = Object.values(hourlyData);
    const machines: {
      [key: string]: machineProps;
    } = {};
    const myEquipments = equipmentsSnap.val() || {};
    const equipments: {
      [key: string]: equipmentProps;
    } = {};
    for await (const e of Object.keys(myEquipments)) {
      if (
        (mySelectedFactory?.equipments &&
          !mySelectedFactory.equipments.includes(e)) ||
        (equipmentsAllowed && !equipmentsAllowed[e])
      )
        continue;
      const equipment: any = myEquipments[e];
      const { name, unit, status, at } = equipment;
      if (cacheUnit[unit] === undefined) throw new Error("NOT_FOUND");
      const { cleanDisconnect, connected, last_seen } = cacheUnit[unit];
      usedUnits.add(unit);
      const _status = connected
        ? status
          ? "ON"
          : "IDLE"
        : cleanDisconnect
        ? "OFF"
        : "NA";
      const myAt = status === "NA" ? last_seen : at;
      equipments[e] = {
        name,
        status: _status,
        electricity: 0,
        at: isNaN(myAt) ? dayjs(myAt).unix() : +myAt,
      };
    }
    for await (const m of Object.keys(myMachines)) {
      if (
        (mySelectedFactory?.machines &&
          !Object.values(mySelectedFactory?.machines || {}).includes(m)) ||
        (machinesAllowed && !machinesAllowed[m])
      )
        continue;
      const machine: any = myMachines[m];
      const {
        machine_status,
        machine_model,
        mold_name: moldName,
        installedMold,
        unit: unitTemp,
        at,
      } = machine || {};
      const mold_name = installedMold ? installedMold.name : moldName;
      const unit = unitTemp || 1;
      if (cacheUnit[unit] === undefined) throw new Error("NOT_FOUND");
      const { cleanDisconnect, last_seen, connected } = cacheUnit[unit];
      usedUnits.add(unit);
      const status = connected
        ? machine_status
          ? "ON"
          : "IDLE"
        : cleanDisconnect
        ? "OFF"
        : "NA";
      const myAt = status === "NA" ? last_seen : at;
      machines[m] = {
        name: machine_model,
        status,
        mold: mold_name,
        shots: 0,
        production_meters: 0,
        production: 0,
        material: 0,
        electricity: 0,
        at: isNaN(myAt) ? dayjs(myAt).unix() : +myAt,
      };
    }
    stats.total.shots.chart = [];
    stats.total.production_meters.chart = [];
    stats.total.production.chart = [];
    stats.total.material.chart = [];
    stats.total.electricity.chart = [];
    stats.hours = [];
    for await (const [hourKey, _hour] of Object.entries(hourlyData)) {
      const hour = _hour as any;
      if (data.shift_a_start === undefined) continue;
      const time = secondsToHourMin(
        +data.shift_a_start + (+hourKey - 1) * 3600,
      );
      const total = {
        shots: 0,
        production: 0,
        production_meters: 0,
        material: 0,
        electricity: 0,
      };
      for await (const _machine of Object.keys(hour.machines || {})) {
        if (
          (mySelectedFactory?.machines &&
            !Object.values(mySelectedFactory?.machines || {}).includes(
              _machine,
            )) ||
          (machinesAllowed && !machinesAllowed[_machine])
        ) {
          delete hour.machines[_machine];
          continue;
        }
        const machine: any = hour.machines[_machine];
        hour.machines[_machine].name = machines[_machine].name;
        if (!machines[_machine]) continue;
        machines[_machine].shots += machine.shots || 0;
        machines[_machine].production_meters += machine.production_meters || 0;
        machines[_machine].production += machine.production || 0;
        machines[_machine].material += machine.material_usage || 0;
        machines[_machine].electricity += machine.electricity_usage || 0;
        total.shots += machine.shots || 0;
        total.production_meters += machine.production_meters || 0;
        total.production += machine.production || 0;
        total.material += machine.material_usage || 0;
        total.electricity += machine.electricity_usage || 0;
        stats.machineHourly.push({
          hour: time,
          machine: machines[_machine].name,
          value: {
            shots: machine.shots || 0,
            production_meters: machine.production_meters || 0,
            production: machine.production || 0,
            material: machine.material_usage || 0,
            electricity: machine.electricity_usage || 0,
          },
        });
      }
      for (const machine of mySelectedFactory?.machines
        ? Object.values(
            (mySelectedFactory?.machines as { [key: number]: string }) || {},
          )
        : Object.keys(machines)) {
        if (!hour.machines) hour.machines = {};
        if (!hour.machines[machine]) {
          hour.machines[machine] = {
            shots: 0,
            name: machines[machine]?.name || "NA",
            production_meters: 0,
            production: 0,
            material_usage: 0,
            electricity_usage: 0,
          };
        }
      }
      for await (const _equipment of Object.keys(hour.equipments || {})) {
        if (
          mySelectedFactory?.equipments &&
          !mySelectedFactory.equipments.includes(_equipment)
        ) {
          delete hour.equipments[_equipment];
          continue;
        }
        const equipment: any = hour.equipments[_equipment];
        hour.equipments[_equipment].name = equipments[_equipment].name;
        total.electricity += equipment.electricity_usage || 0;
        if (!equipments[_equipment]) continue;
        equipments[_equipment].electricity += equipment.electricity_usage || 0;
        if (typeof stats.equipmentHourly === "object")
          stats.equipmentHourly.push({
            hour: time,
            equipment: equipments[_equipment].name,
            value: equipment.electricity_usage || 0,
          });
      }
      for (const equipment of mySelectedFactory?.equipments
        ? Object.values(
            (mySelectedFactory?.equipments as { [key: number]: string }) || {},
          )
        : Object.keys(equipments)) {
        if (!hour.equipments) hour.equipments = {};
        if (!hour.equipments[equipment]) {
          hour.equipments[equipment] = {
            electricity_usage: 0,
            name: equipments[equipment].name,
          };
        }
      }
      stats.hours.push({
        time,
        ...hour,
      });
      stats.total.shots.chart.push(total.shots || 0);
      stats.total.production_meters.chart.push(total.production_meters || 0);
      stats.total.production.chart.push(total.production || 0);
      stats.total.material.chart.push(total.material || 0);
      stats.total.electricity.chart.push(total.electricity || 0);
    }
    stats.total.shots.value = stats.total.shots.chart.reduce(
      (a, b) => a + b,
      0,
    );
    stats.total.production_meters.value =
      stats.total.production_meters.chart.reduce((a, b) => a + b, 0);
    stats.total.production.value = stats.total.production.chart.reduce(
      (a, b) => a + b,
      0,
    );
    stats.total.electricity.value = stats.total.electricity.chart.reduce(
      (a, b) => a + b,
      0,
    );
    stats.total.material.value = stats.total.material.chart.reduce(
      (a, b) => a + b,
      0,
    );
    if (stats.equipmentHourly.length === 0) stats.equipmentHourly = "NOT_FOUND";
    stats.equipments =
      Object.keys(equipments).length > 0 ? equipments : "NOT_FOUND";
    stats.machines = machines;
    const lastHour: any = hours.at(-1);
    const secondLastHour: any = hours.at(-2);
    if (!lastHour || !secondLastHour) {
      resolve(stats);
      return;
    } else {
      const secondLastHourTotal = {
        shots: 0,
        production_meters: 0,
        production: 0,
        material: 0,
        electricity: 0,
      };
      const lastHourTotal = {
        shots: 0,
        production_meters: 0,
        production: 0,
        material: 0,
        electricity: 0,
      };
      for await (const [machineID, _machine] of Object.entries(
        secondLastHour.machines || {},
      )) {
        const machine = _machine as any;
        if (
          mySelectedFactory?.machines &&
          !Object.values(mySelectedFactory?.machines || {}).includes(machineID)
        )
          continue;
        secondLastHourTotal.shots += machine.shots || 0;
        secondLastHourTotal.production_meters += machine.production_meters || 0;
        secondLastHourTotal.production += machine.production || 0;
        secondLastHourTotal.material += machine.material_usage || 0;
        secondLastHourTotal.electricity += machine.electricity_usage || 0;
      }
      for await (const _equipment of Object.values(
        secondLastHour.equipments || {},
      )) {
        if (
          mySelectedFactory?.equipments &&
          !mySelectedFactory.equipments.includes(_equipment)
        )
          continue;
        const equipment = _equipment as any;
        secondLastHourTotal.electricity += equipment.electricity_usage || 0;
      }
      for await (const [machineID, _machine] of Object.entries(
        lastHour.machines || {},
      )) {
        if (
          (mySelectedFactory?.machines &&
            !mySelectedFactory.machines.includes(machineID)) ||
          (machinesAllowed && !machinesAllowed[machineID])
        )
          continue;
        const machine = _machine as any;
        lastHourTotal.shots += machine.shots || 0;
        lastHourTotal.production_meters += machine.production_meters || 0;
        lastHourTotal.production += machine.production || 0;
        lastHourTotal.material += machine.material_usage || 0;
        lastHourTotal.electricity += machine.electricity_usage || 0;
      }
      for await (const _equipment of Object.values(lastHour.equipments || {})) {
        if (
          (mySelectedFactory?.equipments &&
            !mySelectedFactory.equipments.includes(_equipment)) ||
          (equipmentsAllowed && !equipmentsAllowed[_equipment as string])
        )
          continue;
        const equipment = _equipment as any;
        lastHourTotal.electricity += equipment.electricity_usage || 0;
      }
      secondLastHourTotal.shots = secondLastHourTotal.shots / 60;
      secondLastHourTotal.production_meters =
        secondLastHourTotal.production_meters / 60;
      secondLastHourTotal.production = secondLastHourTotal.production / 60;
      secondLastHourTotal.material = secondLastHourTotal.material / 60;
      secondLastHourTotal.electricity = secondLastHourTotal.electricity / 60;
      lastHourTotal.shots = lastHourTotal.shots / thisHourpastMin;
      lastHourTotal.production_meters =
        lastHourTotal.production_meters / thisHourpastMin;
      lastHourTotal.production = lastHourTotal.production / thisHourpastMin;
      lastHourTotal.material = lastHourTotal.material / thisHourpastMin;
      lastHourTotal.electricity = lastHourTotal.electricity / thisHourpastMin;
      // percent change
      stats.total.shots.change = Math.round(
        ((lastHourTotal.shots - secondLastHourTotal.shots) /
          secondLastHourTotal.shots) *
          100,
      );
      stats.total.production_meters.change = Math.round(
        ((lastHourTotal.production_meters -
          secondLastHourTotal.production_meters) /
          secondLastHourTotal.production_meters) *
          100,
      );
      stats.total.production.change = Math.round(
        ((lastHourTotal.production - secondLastHourTotal.production) /
          secondLastHourTotal.production) *
          100,
      );
      stats.total.material.change = Math.round(
        ((lastHourTotal.material - secondLastHourTotal.material) /
          secondLastHourTotal.material) *
          100,
      );
      stats.total.electricity.change = Math.round(
        ((lastHourTotal.electricity - secondLastHourTotal.electricity) /
          secondLastHourTotal.electricity) *
          100,
      );
    }
    if (uid)
      Object.keys(address).forEach((unit: any) => {
        const data = address[unit];
        for (const address of Object.values(data || {})) {
          for (const pin of Object.values(address || {})) {
            if (pin?.split("&").at(0)?.includes("factory")) usedUnits.add(unit);
          }
        }
      });
    Object.keys(envsAllowed || {}).forEach((env) => {
      const unitInAddress = Object.keys(address).find((unit: any) => {
        const data = address[unit];
        return data.extras?.dht_humid === env;
      });
      if (
        unitInAddress !== undefined &&
        (mySelectedFactory?.units === undefined ||
          mySelectedFactory.units.includes(+unitInAddress))
      )
        usedUnits.add(unitInAddress);
    });
    const filteredUnits: {
      [key: string]: any;
    } = {};
    usedUnits.forEach((unit) => {
      filteredUnits[unit as string] = stats.units[unit as string];
    });
    stats.units = filteredUnits;
    resolve(stats);
  });
};
export default fetch;
