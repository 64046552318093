import { CardFooter, CardHeader, Text, useColorMode, Icon, Box, HStack, CardBody, Tabs, TabList, Tab, TabPanels, TabPanel, SimpleGrid, Center } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { BiTimeFive, BiUser } from "react-icons/bi";
import { useParams } from "react-router-dom"
import MyCard from "../../../Components/micro/Card";
import MyTooltip from "../../../Components/micro/MyTooltip";
import dayjs from "../../../Components/Functions/dayjs";
import Get from "../../../Components/Store/hooks/Get";
import { dateAtom } from "../../../Components/Store/atoms";
import fetch from "./fetch";
import { MachineStatusType } from "../../Machines/types";
import secFormatter from "../../../Components/Functions/formatters/secFormatter";
import MinifiedNumberFormatter from "../../../Components/micro/MinifiedNumberFormatter";
import LoadingCard from "./LoadingCard";
import CurrentStats from "./CurrentStats";
import Ontime from "./Ontime";
import Downtime from "./Downtime";
import { downtimeCategoriesType } from "./Downtime/categories";
import { IoMdAdd } from "react-icons/io";
import AddUpdateTarget from "./AddUpdateTarget";
import { TbTimelineEventPlus } from "react-icons/tb";
import Categorize from "./Downtime/Categorize";
import HourlyStats from "./HourlyStats";
import { Helmet } from "react-helmet";
import NewFeatureAlert from "../../../Components/micro/NewFeatureAlert";

interface DataToSet {
    name: string;
    target: {
        defaultMonitorDowntime: boolean;
        machineMonitorDowntime: boolean;
        name: string,
        due?: string,
        target: number,
        hourlyTarget: number,
        started: number;
        notifications: ("milestone" | "due-missed")[],
        notificationCategories: string[]
    },
    mold: {
        name: string;
        since: number;
        cycleTime: number;
        cavities: number;
    };
    status: {
        status: MachineStatusType;
        since: number;
    };
    progress: {
        total: number;
        current: number;
        track: number;
        shouldbe: number;
    };
    hours: {
        production: number;
        downtime: number;
        ontime: number;
        time: string;
        date: string;
    }[];
    OEE: number;
    timing: {
        ontime: number;
        downtime: {
            total: number;
            logs: {
                start: number;
                end: number;
                reason?: {
                    category: downtimeCategoriesType;
                    note: string;
                    scheduled: boolean;
                };
                id: string;
            }[] | "NOT_FOUND"
        }
        projectedEndTime: number; //unix
    };
    lastCompletedTarget: {
        id: string;
        name: string;
        started: number;
        completed: number;
        efficiency: number;
        hours: {
            production: number;
            efficiency: number;
            time: string;
            date: string;
        }[];
        target: number;
    } | null;
    operator: {
        name: string;
        shift: string;
    };
    notificationCategories: string[],
    updated: number;
};
const Target = () => {
    const { machineID } = useParams();
    const { colorMode } = useColorMode();
    const { text, border, popoverContentBgColor } = useMemo(() => ({
        text: `${colorMode}.text`,
        border: `${colorMode}.border`,
        popoverContentBgColor: `${colorMode}.popoverContentBgColor`,
    }), [colorMode]);
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [data, setData] = useState<DataToSet | "NOT_FOUND" | null>(null);
    const date = Get(dateAtom);
    const refetch = () => setRefresh(prev => !prev);
    const showFeatureAlert = useMemo(() => {
        const value = localStorage.getItem("showFeatureAlert-pt") || 0;
        localStorage.setItem("showFeatureAlert-pt", String(Number(value) + 1));
        return Number(value) < 50;
    }, []);

    useEffect(() => {
        setData(null);
    }, [machineID]);

    useEffect(() => {
        if (!machineID || !date) return;
        try {
            fetch(machineID).then(setData);
        } catch (err) {
            console.error(err);
            setData("NOT_FOUND");
        }
    }, [date, refresh, machineID]);

    useEffect(() => {
        const interval = setInterval(() => {
            refetch();
        }, 1000 * 30);

        return () => clearInterval(interval);
    }, []);

    const hasTarget = useMemo(() => {
        if (!data || data === "NOT_FOUND") return false;
        return data.target.target > 0;
    }, [data]);

    const updateLog = (log: {
        start: number;
        end: number;
        reason: {
            category: downtimeCategoriesType;
            note: string;
            scheduled: boolean;
        };
        id: string;
    }) => {
        if (data === "NOT_FOUND" || data === null) return;
        if (data.timing.downtime.logs === "NOT_FOUND") return;
        const index = data.timing.downtime.logs.findIndex(d => d.id === log.id);
        if (index === -1) return;
        const newData = [...data.timing.downtime.logs];
        newData[index] = log;
        setData({
            ...data,
            timing: {
                ...data.timing,
                downtime: {
                    ...data.timing.downtime,
                    logs: newData
                }
            }
        });
    }

    return !data ? <LoadingCard />
        : data === "NOT_FOUND" ? <NewFeatureAlert
            title="No Machine found!"
            description="No Machine found with this ID, please go back to homepage and try again or contact support if issue persists." />
            : <>
                {showFeatureAlert && <NewFeatureAlert mb={3} description="You can now view and download previous production target reports on the Reports page. Check it out today! 📊" />}
                <MyCard p={0} w="100%">
                    <Helmet>
                        <title>{data.name} | Production Targets | industrialpmr</title>
                    </Helmet>
                    <CardHeader py={3} px={3} display={"flex"} alignItems="center" justifyContent={"space-between"} borderBottom={`1px solid`} borderColor={border}>
                        <Box lineHeight={1.5}>
                            <Text fontWeight={600} fontSize={"3xl"}>{data.name}</Text>
                            <Text fontWeight={500} fontSize={"sm"}>{data.target.name}</Text>
                        </Box>
                        <HStack>
                            <Box w="10px" h="10px" borderRadius="full" className={data.status.status === "ON" ? "blink" : undefined} bgColor={data.status.status === "ON" ? "green" : data.status.status === "IDLE" ? "orange" : data.status.status === "OFF" ? "red" : "gray"}></Box>
                            <Text fontWeight={500} fontSize={"md"}><span style={{
                                fontWeight: 700
                            }}>{data.status.status}</span>{` for ${dayjs.unix(data.status.since).fromNow(true)}`}</Text>
                        </HStack>
                    </CardHeader>
                    <Tabs onChange={setTabIndex} colorScheme="orange">
                        <TabList
                            justifyContent={"space-between"}>
                            <Tab py={2} w="100%">
                                <Box>
                                    <Text fontSize="xs" fontWeight={700} textTransform="uppercase" opacity={0.9}>current Stats</Text>
                                    <Text fontSize="md" fontWeight={500}>{data.target.target ? <>{data.target.name?.toUpperCase()} (<MinifiedNumberFormatter suffixOnlyInTooltip number={data.target.target} suffix="pc(s)" />)</> : "NONE"}</Text>
                                </Box>
                            </Tab>
                            <Tab py={2} w="100%">
                                <Box>
                                    <Text fontSize="xs" fontWeight={700} textTransform="uppercase" opacity={0.9}>ontime</Text>
                                    <Text fontSize="md" fontWeight={500}>{secFormatter(data.timing.ontime)}</Text>
                                </Box>
                            </Tab>
                            <Tab py={2} w="100%">
                                <Box>
                                    <Text fontSize="xs" fontWeight={700} textTransform="uppercase" opacity={0.9}>downtime</Text>
                                    <Text fontSize="md" fontWeight={500}>{secFormatter(data.timing.downtime.total)}</Text>
                                </Box>
                            </Tab>
                            <Tab py={2} w="100%">
                                <Box>
                                    <Text fontSize="xs" fontWeight={700} textTransform="uppercase" opacity={0.9}>hourly stats</Text>
                                    <Text fontSize="md" fontWeight={500}>{String(data.hours.length).padStart(2, "0") + " Hours Recorded"}</Text>
                                </Box>
                            </Tab>
                        </TabList>
                        <CardBody py={10} px={4}>
                            <TabPanels>
                                <TabPanel>
                                    {tabIndex === 0 && <CurrentStats data={data} />}
                                </TabPanel>
                                <TabPanel>
                                    {tabIndex === 1 && <Ontime hours={data.hours} />}
                                </TabPanel>
                                <TabPanel>
                                    {tabIndex === 2 && <Downtime data={data.timing.downtime.logs} />}
                                </TabPanel>
                                <TabPanel>
                                    {tabIndex === 3 && <HourlyStats hourlyTarget={data.target.hourlyTarget} data={data.hours} />}
                                </TabPanel>
                            </TabPanels>
                        </CardBody>
                        <SimpleGrid
                            columns={{
                                base: 1,
                                md: 2
                            }}
                            mt={5}>
                            <AddUpdateTarget refetch={refetch} target={data.target} mold={data.mold} machineID={machineID} notificationCategories={data.notificationCategories}>
                                <Center
                                    transition={"all ease 0.2s"}
                                    py={5}
                                    cursor={"pointer"}
                                    _hover={{
                                        textDecoration: "underline",
                                        backgroundColor: popoverContentBgColor
                                    }}
                                    border={"1px dashed"}
                                    borderColor={border}>
                                    <HStack alignItems={"center"} justifyContent="center">
                                        <Icon as={IoMdAdd} fontSize="2xl" />
                                        <Text fontWeight={600} fontSize="2xl">{hasTarget ? "Update Target" : "Add Target"}</Text>
                                    </HStack>
                                </Center>
                            </AddUpdateTarget>
                            <Box as={data.status.status !== "ON" && ((data.timing.downtime.logs.at(-1) as any)?.reason === undefined) ? Categorize : Box} downtime={data.timing.downtime.logs.at(-1) || ""} updateLog={updateLog}>
                                <Center
                                    transition={"all ease 0.2s"}
                                    py={5}
                                    cursor={data.status.status !== "ON" && ((data.timing.downtime.logs.at(-1) as any)?.reason === undefined) ? "pointer" : undefined}
                                    _hover={data.status.status !== "ON" && ((data.timing.downtime.logs.at(-1) as any)?.reason === undefined) ? {
                                        textDecoration: "underline",
                                        backgroundColor: popoverContentBgColor
                                    } : undefined}
                                    border={"1px dashed"}
                                    borderColor={border}>
                                    <HStack alignItems={"center"} justifyContent="center">
                                        <Icon as={TbTimelineEventPlus} fontSize="2xl" />
                                        <Text fontWeight={600} fontSize="2xl">Catgorize Downtime</Text>
                                    </HStack>
                                </Center>
                            </Box>
                        </SimpleGrid>
                    </Tabs>
                    <CardFooter
                        borderTop={`1px solid`}
                        borderTopColor={border}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={2}
                        px={4}>
                        <Text
                            display={"flex"}
                            alignItems={"center"}
                            textAlign={"center"}
                            fontSize={"xs"}
                            color={text}
                            fontWeight={600}>
                            <Icon
                                fontSize={"md"}
                                color={text}
                                mr={1}
                                as={BiUser} />
                            <MyTooltip label={`Shift Timing: ${data.operator.shift}`}>
                                {data.operator.name}
                            </MyTooltip>
                        </Text>
                        <Text
                            display={"flex"}
                            alignItems={"center"}
                            textAlign={"center"}
                            fontSize={"xs"}
                            color={text}
                            fontWeight={600}>
                            <Icon
                                fontSize={"md"}
                                color={text}
                                mr={1}
                                as={BiTimeFive} />
                            <MyTooltip label={dayjs.unix(data.updated).format("DD-MM-YYYY HH:mm:ss")}>
                                {dayjs.unix(data.updated).fromNow()}
                            </MyTooltip>
                        </Text>
                    </CardFooter>
                </MyCard>
            </>
}

export type { DataToSet };
export default Target;